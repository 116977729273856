import { LoginType } from "interfaces";
import { useState } from "react";
import { Select } from "semantic-ui-react";

export default function CheckRankInput({
  onChange,
  onSearch,
  showType
}: {
  onChange?: Function;
  onSearch: Function;
  showType?: boolean;
}) {
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<LoginType | -1>(-1);
  return (
    <div className="border border-[#F1B27A] rounded-md flex flex-col lg:flex-row w-full">
      {showType && <Select
        className="custom-select px-4 ring-0 border-0 top-0"
        placeholder="Login type"
        onChange={(evt, { value }) => {
          console.log({ value, evt })
          setType(Number(value));
        }}
        options={[
          { value: -1, text: "All" },
          { value: LoginType.BSC, text: "BSC" },
          { value: LoginType.Avalanche, text: "AVAX" },
          { value: LoginType.Okex, text: "OKC" },
          { value: LoginType.Email, text: "Email" }
        ]}
        value={type}
        defaultValue={type}
      />}
      <input
        type="text"
        onChange={(evt: any) => {
          if (onChange) {
            onChange(evt.target.value);
          }
          setSearch(evt.target.value);
        }}
        className="bg-[#FCE8D5] text-[#6C6D76] px-4 min-w-[250px] w-full ring-0 border-0 top-0"
        placeholder="Enter wallet address/email"
      />

      <div
        className="bg-[#F9C744] ring-0 border-0 p-2 text-outline-brown font-cookie cursor-pointer text-white text-center w-full min-w-[160px]"
        onClick={() => {
          onSearch(search, type);
        }}
      >
        <div className="translate-y-[2px]">
          Check your rank
        </div>
      </div>
    </div>
  );
}
