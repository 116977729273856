import Phaser from "phaser";
import "phaser/plugins/spine/dist/SpinePlugin";
// import "./SpinePlugin.min.js";
import { getSkinList, getAnimName } from "./spineutils";
let partObjects = {};
//acc: back
//fleg: front leg
//parts: body
//head
const name = ["beast", "tectos", "mythic", "celest", "chaos"];
const spineName = [
  {
    key: `tail`,
    json: `JSON Tail_Mongens/TAIL.json`,
    atlas: `JSON Tail_Mongens/TAIL.atlas.txt`,
  },
  {
    key: `back`,
    json: `JSON ACC_Mongens/BACK.json`,
    atlas: `JSON ACC_Mongens/BACK.atlas.txt`,
  },
  {
    key: `beast_parts`,
    json: `JSON Beast/Beast.json`,
    atlas: `JSON Beast/Beast.atlas.txt`,
  },
  {
    key: `tectos_parts`,
    json: `JSON Tectos/Tectos.json`,
    atlas: `JSON Tectos/Tectos.atlas.txt`,
  },
  {
    key: `mythic_parts`,
    json: `JSON Mythic/Mythic.json`,
    atlas: `JSON Mythic/Mythic.atlas.txt`,
  },
  {
    key: `celest_parts`,
    json: `JSON Celest/Celest.json`,
    atlas: `JSON Celest/Celest.atlas.txt`,
  },
  {
    key: `chaos_parts`,
    json: `JSON Chaos/Chaos.json`,
    atlas: `JSON Chaos/Chaos.atlas.txt`,
  },

  {
    key: `head`,
    json: `JSON Head_Mongens/HEAD.json`,
    atlas: `JSON Head_Mongens/HEAD.atlas.txt`,
  },

  {
    key: `beast_fleg`,
    json: `JSON Beast_Fleg/Beast_Fleg.json`,
    atlas: `JSON Beast_Fleg/Beast_Fleg.atlas.txt`,
  },
  {
    key: `tectos_fleg`,
    json: `JSON Tectos_Fleg/Tectos_Fleg.json`,
    atlas: `JSON Tectos_Fleg/Tectos_Fleg.atlas.txt`,
  },
  {
    key: `mythic_fleg`,
    json: `JSON Mythic_Fleg/Mythic_Fleg.json`,
    atlas: `JSON Mythic_Fleg/Mythic_Fleg.atlas.txt`,
  },
  {
    key: `celest_fleg`,
    json: `JSON Celest_Fleg/Celest_Fleg.json`,
    atlas: `JSON Celest_Fleg/Celest_Fleg.atlas.txt`,
  },
  {
    key: `chaos_fleg`,
    json: `JSON Chaos_Fleg/Chaos_Fleg.json`,
    atlas: `JSON Chaos_Fleg/Chaos_Fleg.atlas.txt`,
  },
];
let queries = [];
let id = 0;
let cache = {};
let ready = [];
let capturing = false;
let delay = 0;
export function queryImage(dna, cb) {
  let key = JSON.stringify(dna);
  if (cache[key]) {
    return cb(cache[key]);
  }
  id++;
  queries.push({
    id,
    dna,
    callback: (img) => {
      cache[key] = img;
      cb(img);
    },
  });
  return id;
}
export function removeQuery(id) {
  queries = queries.filter((i) => i.id !== id);
}
let gameStart = false;
export default function startGame() {
  if (gameStart) return;
  gameStart = true;
  class Example extends Phaser.Scene {
    dna = [
      [1, 3, 1],
      [1, 2, 1],
      [1, 2, 2],
      [1, 3, 1],
      [1, 2, 1],
      [1, 2, 2],
      [1, 2, 1],
      [1, 2, 2],
      [2, 3, 18],
      [3],
    ];
    animParts = [];
    constructor() {
      super();
    }
    getSpineKey(part, race) {
      switch (part) {
        case 0: //
          return `${name[race]}_parts`;
        case 1: //head
          return "head";
        case 2: //eyes
          return "head";
        case 3:
          return "head";
        case 4: //tail
          return "tail";
        case 5: //back
          return "back";
        case 6:
          return `${name[race]}_fleg`;
        case 7:
          return `${name[race]}_parts`;
        case 8:
          return `head`;
      }
    }

    preload() {
      spineName.forEach((s) => {
        // @ts-ignore
        this.load.spine(s.key, `tech/${s.json}`, `tech/${s.atlas}`, false);
      });
    }

    applyDNA() {
      if (capturing) {
        return;
      }
      if (ready.length) {
        let q = ready.pop();
        capturing = true;
        game.renderer.snapshot(
          (rs) => {
            if (q.callback) {
              rs = rs.src;
              q.callback(rs);
              capturing = false;
            }
          },
          { encoderOptions: 1 }
        );
        return;
      }
      if (!queries.length) {
        return;
      }
      let query = queries.pop();
      ready.push(query);
      let skins = getSkinList(query.dna);
      let orders = [[4], [5], [0, 7, 9], [1, 2, 3, 8], [6]];
      for (var k in partObjects) {
        partObjects[k].alpha = 0;
      }
      orders.forEach((o, index) => {
        let key = this.getSpineKey(o[0], query.dna[o[0]][0]);
        partObjects[key].alpha = 1;
        let part = partObjects[key];
        // @ts-ignore
        let mainSkin = new window.spine.Skin(skins[o[0]]);
        if (!mainSkin) {
          console.log("skin not found ", { skin: skins[o[0]], key });
        }
        o.forEach((bodypart) => {
          let sk = part.skeleton.data.findSkin(skins[bodypart]);
          if (sk) {
            mainSkin.addSkin(sk);
          } else {
            console.log("skin not found ", skins[bodypart]);
          }
        });
        part.skeleton.setSkin(mainSkin);
        part.skeleton.setSlotsToSetupPose();
        part.skeleton.updateCache();
        part.play(getAnimName("Idle", query.dna), true);
      });
    }

    create() {
      spineName.forEach((k) => {
        partObjects[k.key] = this.make
          // @ts-ignore
          .spine({
            x: 256,
            y: 500,
            key: k.key,
            loop: true,
          })
          .setScale(0.9, 0.9);
        partObjects[k.key].alpha = 0;
      });
    }
    update() {
      this.applyDNA();
    }
  }

  const config = {
    type: Phaser.AUTO,
    parent: "phaser-example",
    width: 512,
    height: 512,
    scene: Example,
    transparent: true,
    backgroundColor: "rgba(255,110,110,1)",
    plugins: {
      scene: [
        {
          key: "SpinePlugin",
          // @ts-ignore
          key: window.SpinePlugin.name,
          // @ts-ignore
          plugin: window.SpinePlugin,
          mapping: "spine",
          drawDebug: true,
        },
      ],
    },
  };
  const game = new Phaser.Game(config);
}
