import { RewardEntity } from "interfaces";

const BoxData: any = {
  Box_Of_Dracula_Mini_Bot: {
    image: "13",
    title: "Box Of Dracula Mini Bot",
  },
  Box_Of_Golden_Moon: {
    image: "9",
    title: "Box of Golden Moon",
  },
  Box_Of_Moon_Mini_Bot: {
    image: "10",
    title: "Box of Chibi Moonbear",
  },
  Box_Of_Creepy_Night: {
    image: "Halloween_Box",
    title: "Box Of Creepy Night",
  },
  Stone_Pounch: {
    image: "14",
    title: "Stone Pouch",
  },
  Rune_Pounch_A1: {
    image: "15",
    title: "Common Rune Pouch",
  },
  Rune_Pounch_A2: {
    image: "16",
    title: "Uncommon Rune Pouch",
  },
  Rune_Pounch_A3: {
    image: "17",
    title: "Rare Rune Pouch",
  },
  Rune_Pounch_A4: {
    image: "18",
    title: "Epic Rune Pouch",
  },
  Trap_Box_B1: {
    image: "25",
    title: "Common Cursed Trap Tool",
  },
  Trap_Box_B2: {
    image: "26",
    title: "Uncommon Cursed Trap Tool",
  },
  Trap_Box_B3: {
    image: "27",
    title: "Rare Cursed Trap Tool",
  },
  Trap_Box_B4: {
    image: "28",
    title: "Epic Cursed Trap Tool",
  },
  Trap_Box_A1: {
    image: "30",
    title: "Common Elemental Trap Tool",
  },
  Trap_Box_A2: {
    image: "31",
    title: "Uncommon Elemental Trap Tool",
  },
  Trap_Box_A3: {
    image: "32",
    title: "Rare Elemental Trap Tool",
  },
  Trap_Box_A4: {
    image: "33",
    title: "Epic Elemental Trap Tool",
  },
  Trap_Box_C2: {
    image: "36",
    title: "Uncommon Trap Tool",
  },
  Trap_Box_C3: {
    image: "37",
    title: "Rare Trap Tool",
  },
  Trap_Box_C4: {
    image: "38",
    title: "Epic Trap Tool",
  },
  Military_Box_A1: {
    image: "20",
    title: "Common War Chest",
  },
  Military_Box_A2: {
    image: "21",
    title: "Uncommon War Chest",
  },
  Military_Box_A3: {
    image: "22",
    title: "Rare War Chest",
  },
  Military_Box_A4: {
    image: "23",
    title: "Epic War Chest",
  },
  Box_of_Healthy_Life: {
    image: "40",
    title: "Box Of Healthy Life",
  },
  Box_of_Delight: {
    image: "41",
    title: "Box of Delight",
    desc: "Box of Delight is the 4th decorative box to be released on Dec 2022. The box includes 11 items.",
  },
  Box_Of_Kramper_Mini_Bot: {
    image: "42",
    title: "Chibi Krampus",
    desc: "Chibi Krampus is a limited reward for the best players in the Christmas event 2022.",
  },
  Box_of_Reunion: {
    image: "43.webp",
    title: "Box of Reunion",
    desc: "",
  },
  Box_Of_Nian_Mini_Bot: {
    image: "44.webp",
    title: "Chibi Nian",
    desc: "",
  },
  Box_of_Sweet_Love: {
    image: "45.webp",
    title: "Box of Sweet Love",
  },
  Box_Of_Cupid_Mini_Bot: {
    image: "46.webp",
    title: "Chibi Cupid",
  },
  "mongen_habitat_A": {
    "image": "47.webp",
    "title": "Uncommon Habitat Treasure",
    "desc": "The treasure contains several landplot"
  },
  "mongen_habitat_B": {
    "image": "48.webp",
    "title": "Rare Habitat Treasure",
    "desc": "The treasure contains several landplot"
  },
  "mongen_habitat_C": {
    "image": "49.webp",
    "title": "Epic Habitat Treasure",
    "desc": "The treasure contains several landplot"
  },
  "farm_landplot_A": {
    "image": "50.webp",
    "title": "Uncommon Farm Treasure",
    "desc": "The treasure contains several landplot"
  },
  "farm_landplot_B": {
    "image": "51.webp",
    "title": "Rare Farm Treasure",
    "desc": "The treasure contains several landplot"
  },
  "farm_landplot_C": {
    "image": "52.webp",
    "title": "Epic Farm Treasure",
    "desc": "The treasure contains several landplot"
  },
  "Box_of_Surprise_Eggs": {
    "image": "53.webp",
    "title": "Box of Surprise Eggs",
    "desc": "Box of Surprise Eggs is the 7th decorative box to be released on Mar 2023. The box includes 12 items."
  },
  "Box_Of_Rabbit_Mini_Bot": {
    "image": "54.webp",
    "title": "Chibi Rabbit",
    "desc": "Chibi Rabbit is a limited reward for the best players in the Christmas event 2022."
  },
  "freyr_set_A": {
    "image": "55.webp",
    "title": "Freyr set A"
  },
  "freyr_set_B": {
    "image": "56.webp",
    "title": "freyr_set_B"
  },
  "freyr_set_C": {
    "image": "57.webp",
    "title": "Freyr set C"
  },
  "heimdall_set_A": {
    "image": "58.webp",
    "title": "Heimdall set A"
  },
  "heimdall_set_B": {
    "image": "59.webp",
    "title": "Heimdall set B"
  },
  "heimdall_set_C": {
    "image": "60.webp",
    "title": "Heimdall set C"
  },
  "tyr_set_A": {
    "image": "61.webp",
    "title": "Tyr set A"
  },
  "tyr_set_B": {
    "image": "62.webp",
    "title": "Tyr set B"
  },
  "tyr_set_C": {
    "image": "63.webp",
    "title": "Tyr set C"
  },
  "Box_of_Deep_Sea": {
    "image": "64.webp",
    "title": "Box of Deep Sea"
  },
  "Box_Of_Mermaid_Mini_Bot": {
    "image": "65.webp",
    "title": "Chibi Mermaid"
  },
  "skin_box_egyptian": {
    "image": "66.webp",
    "title": "Treasure of Dead Desert"
  }
};
export function getBoxIcon(reward: RewardEntity) {
  if (reward?.box_name) {
    let icon = BoxData[reward.box_name]?.image;
    if (icon !== null && icon !== undefined) {
      if (icon.includes(".")) {
        return `box/${icon}`;
      } else {
        return `box/${icon}.webp`;
      }
    }
  }
  return null;
}
