import { AuctionEntity, BodyPart, Rarity } from "interfaces";
import { useEffect, useState, useMemo } from "react";
import { RootState } from "reducer/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api, socketAuction } from "services";
import { Tabs, Pagination } from "antd";
import { Modal, Select } from "semantic-ui-react";
import { EVENT_SOCKET, DEFAULT_ITEMS_PER_PAGE, OPTIONS_RARITY } from "config";
import AuctionItem from "./auction-item";

const { TabPane } = Tabs

export default function AuctionPage({ menuAuction, isP2P }: { menuAuction: any[], isP2P?: boolean }) {
  const user = useSelector((state: RootState) => state.user);
  const nav = useNavigate();
  const [events, setEvents] = useState<any[]>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>(menuAuction[0].name);
  const [openRule, setOpenRule] = useState<boolean>(false);
  const [topBids, setTopBids] = useState<any>([])
  const [itemUpdate, setItemUpdate] = useState<any>({})
  const [totalItem, setTotalItem] = useState(0)
  const [page, setPage] = useState(0)
  const [showTopBid, setShowTopBid] = useState(true)
  const [statusOpen, setStatusOpen] = useState(0)
  const [rarity, setRarity] = useState<any>(OPTIONS_RARITY[0].value)
  const [trigger, setTrigger] = useState<boolean>(false);
  const ITEMS_PER_PAGE = 20;

  async function load() {
    try {
      setLoading(true);
      let apiUrl = menuAuction.find(el => el.name == activeTab).api
      let url = Array.isArray(apiUrl) ? apiUrl[statusOpen] : apiUrl
      let rs = await api.getBidData(url, user?.id, page ?? 1, ITEMS_PER_PAGE)
      let result
      if (isP2P && activeTab == "My Inventory") {
        result = rs.mongenInfos.concat(rs.plotInfos)
      } else result = rs.data ? rs.data : rs
      setEvents(result);
      if (rs.metadata && isP2P) {
        setTotalItem(rs.metadata.total)
        setPage(rs.metadata.page)
      } else {
        setTotalItem(result.length)
        setPage(0)
      }
    } catch (err) {
      setEvents([])
    } finally {
      setLoading(false);
    }
  }

  const getTopBid = async () => {
    const res = await api.getTopBid(isP2P ? "/p2p-auction/bid-history" : "/auction-items/bid-history")
    setTopBids(res)
  }

  async function checkSocketResult(type: string, payload: any) {
    if (type === "auction/new-item") {
      load()
    }
    if (type === "auction/bid") {
      getTopBid()
      setItemUpdate(payload.data)
    }
  }

  useMemo(() => {
    load();
  }, [activeTab, user?.id, menuAuction, page, statusOpen, trigger]);

  useEffect(() => {
    setStatusOpen(0)
  }, [activeTab, isP2P])

  useEffect(() => {
    setActiveTab(menuAuction[0].name)
    getTopBid()
  }, [menuAuction, isP2P])

  useEffect(() => {
    getTopBid()
    async function loadSocket() {
      let nSocket: any;
      if (socketAuction.socket?.connected === undefined) {
        nSocket = socketAuction.initiateSocket();
      } else {
        nSocket = socketAuction.socket;
      }
      await Promise.all(EVENT_SOCKET.map((el) => {
        nSocket.off(el);
        nSocket.on(el, (payload: any) => {
          if (payload?.data) checkSocketResult(el, payload)
        });
      }))
    }
    loadSocket()
  }, []);

  const onChangeTab = (value: any) => {
    setActiveTab(value);
  };

  const onChangePage = (e: number) => {
    setPage(e)
  }

  const onClickItem = (src: string, id: string) => {
    if (!isP2P) {
      socketAuction.disconnectSocket()
      nav(`/${src}/${id}`)
    } else {
      nav(`/${src}/${id}`)
    }
  }

  const renderTopBiders = useMemo(() => {
    return topBids?.map((el: any) => <div className="flex mb-[4px]">
      <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
      <div className="text-[#E1E2E9] text-normal text-[12px]">
        {el.user_name || el.user_id} bids <span className="font-semibold">{el.price} MSTR </span> on
        <span className="text-[#E89F01] cursor-pointer"
          onClick={() => onClickItem(isP2P ? "auction-p2p" : "auction-b2c", el.auction_item._id)}>
          {el.auction_item.auction_name || el.auction_item.item_data.name}
        </span>
      </div>
    </div>)
  }, [itemUpdate, topBids])

  return (
    <>
      <div className="w-full  relative z-10 sm:flex-row auction-container">
        <Tabs className=" w-full  sm:flex" activeKey={activeTab} onChange={onChangeTab} tabPosition="left">
          <TabPane
            disabled
            className="w-full p-14"
            tab={
              <div className="w-full flex items-center">
                <div className="text-[#EBC276] px-6 text-[24px] font-bold mb-3">{isP2P ? "P2P Auction" : "Bid to earn"}</div>
                <Select
                  placeholder="Select subject"
                  className="h-[40px] mb-3 sm:mb-16 drop-down-auction"
                  onChange={(evt, { value }) => {
                    onChangeTab(value)
                  }}
                  defaultValue={menuAuction[0].name}
                  options={menuAuction.map((el, index) => { return { key: index, value: el.name, text: el.name } })}
                />
              </div>
            }>
          </TabPane>
          {menuAuction.map((e: any, index: number) => (
            <TabPane
              className=" w-full p-[20px] sm:px-14 sm:py-[20px]"
              tab={
                <div className=" hidden sm:block px-6 py-[10px] border-t border-solid border-[#494949] cursor-pointer auction-menu-active">{e.name}</div>
              }
              key={e.name}
            >
              {e.name == activeTab ?
                <>
                  <div className="flex items-center justify-between border-b border-solid border-[#494949] pb-[10px]">
                    <div className="text-[#E1E2E9] sm:text-3xl text-[20px] font-semibold flex items-start sm:pb-4 ">
                      {activeTab}
                      <div className="w-max h-max ml-3 text-sm text-[#E89F01] font-normal pt-[12px]">{totalItem ? `( ${totalItem} )` : (events?.length > 0 ? `( ${events?.length} )` : `( 0 )`)} </div>
                      {activeTab == "My Inventory" && <div className="text-[#CCCCCC] font-normal text-[12px] leading-5 ml-[27px] mt-[12px]">
                        Only Epic and Legendary unlocked items can be auctioned
                      </div>}
                    </div>
                    <div className="flex">
                      {activeTab != "My Inventory" && <div className="relative cursor-pointer mr-[20px]"
                        onClick={() => {
                          setActiveTab("My Inventory");
                        }}
                      >
                        <img
                          src={require("./create-auction-btn.png")}
                          className="h-10 "
                          onClick={() => {
                            setOpenRule(true);
                          }}
                          alt="create"
                        />
                        <div className="absolute text-white top-0 bottom-0 left-0 right-0 flex items-center justify-center">Create an auction</div>
                      </div>}
                      <div className="relative cursor-pointer"
                        onClick={() => {
                          setOpenRule(true);
                        }}>
                        <img
                          src={require("./rule-btn3.png")}
                          className="h-10"
                          alt="rule"
                        />
                        <div className="absolute text-white top-0 bottom-0 left-0 right-0 flex items-center justify-center">Rule</div>
                      </div>
                    </div>
                  </div>
                  {isP2P && activeTab == "Open Auctions" && <div className="flex my-[16px]">
                    <div onClick={() => setStatusOpen(0)} className={`px-[6px] py-[8px] rounded-md border-[#8A7737] border text-white cursor-pointer mr-[16px] ${statusOpen == 0 && "bg-[#8A7737]"}`}>
                      Opening
                    </div>
                    <div onClick={() => setStatusOpen(1)} className={`px-[6px] py-[8px] rounded-md border-[#8A7737] border text-white cursor-pointer ${statusOpen == 1 && "bg-[#8A7737]"}`}>
                      Coming
                    </div>
                  </div>}
                  {events !== null && activeTab == "My Inventory" && !loading && <Select
                    placeholder="Select"
                    className="mt-[20px] filter-rarity-auction"
                    onChange={(evt, { value }) => {
                      setRarity(value)
                    }}
                    defaultValue={Rarity.All}
                    options={OPTIONS_RARITY}
                  />}
                  {loading && (
                    <p className="font-cookie text-3xl text-center text-[#eac17a]">
                      Loading...
                    </p>
                  )}
                  {!loading && <div className="auction-list flex mt-[20px] w-full flex-wrap justify-center sm:justify-start">
                    {(events?.length && !loading &&
                      events?.filter((el) => {
                        if (rarity === Rarity.All) {
                          return true;
                        } else {
                          if (el?.dna) {
                            return el.dna[el.dna.length - 1][0] === rarity
                          } else {
                            return el?.rarity === rarity
                          }
                        }
                      }).map((el: AuctionEntity) => (
                        <AuctionItem
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                          auction={el}
                          tabInfo={e}
                          key={el._id}
                          itemUpdate={itemUpdate}
                          trigger={trigger}
                          setTrigger={setTrigger}
                        />
                      )))}
                    {!user?.id && (
                      <p className="text-red text-center">
                        You're not signing in, please sign-in first!
                      </p>
                    )}
                  </div>
                  }
                  {totalItem > DEFAULT_ITEMS_PER_PAGE && activeTab !== "My Inventory" && <Pagination
                    defaultCurrent={1}
                    total={totalItem}
                    pageSize={DEFAULT_ITEMS_PER_PAGE}
                    current={page}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    // showPrevNextJumpers={false}
                    className="flex text-white items-center justify-center auction-paging text-[16px] mb-[20px]"
                  />}
                </> : null}

            </TabPane>
          ))}

        </Tabs>
        <div className="absolute left-0 bottom-0">
          <div className="hidden sm:block w-full relative mt-[20px] p-[2px] ">
            {showTopBid && <><img className="w-full h-[300px]" src="/assets/images/bg-top-bidder.png" alt="top" />
              <div className=" absolute w-full h-[255px] overflow-y-scroll top-[25px] left-0 px-[12px] list-bidders">
                {renderTopBiders}
              </div>
              <img onClick={() => setShowTopBid(false)} className="absolute right-[-5px] top-[-10px] cursor-pointer" src="/assets/images/close-icon.png" alt="close" />
            </>}
            <img onClick={() => setShowTopBid(true)} className="cursor-pointer" src="/assets/images/top-bidder-icon.png" alt="top" />
          </div>
        </div>

      </div>


      <Modal
        basic
        open={openRule}
        onClose={() => {
          setOpenRule(false);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#0F1011] relative rounded-lg pb-[40px] ">
            <img
              onClick={() => {
                setOpenRule(false);
              }}
              src={require("../home/event-item/btn-close-modal.png")}
              className="absolute -top-3 -right-3 cursor-pointer"
              alt="close"
            />
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              Rules
            </p>
            <img
              src={require("../home/event-item/modal-separate.png")}
              className="mx-auto"
              alt="modal"
            />
            <div className="px-[50px] text-[#EBC276] text-[24px] leading-9 font-bold">
              {isP2P ? "Monstera P2P Auction is a new way for players to sell NFT, everyone can bid to achieve NFT at best price." : "Monsterra Auction is a new way of doing NFT drops, where everyone wins and bid to earn."}
              <div className="mt-[41px] text-[#E1E2E9] text-[14px] leading-[21px]  font-normal">
                <div className="opacity-40 mb-[12px]">How it works</div>
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  {isP2P ? "First, place a bid. If someone else bid higher than you, your bid will be REFUNDED" : "First, place a bid. If someone else bid higher than you, your bid will be REFUNDED, plus an additional PAYOUT, up to 10% of your bid amount."}
                </div>

                {!isP2P && <div className=" mb-[12px]">
                  <div className="flex">
                    <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                    <div className="text-[#FFAE19]">Estimated Profit = ((Your Bid - Previous Bid)/Previous Bid)/10*Your bid </div>
                  </div>
                  <div className="ml-[20px]">Note: Maximum of (Your Bid - Previous Bid)/Previous Bid = 1</div>
                </div>}
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  Continue bidding until the timer runs out. If a bid is placed near the end of the auction (closer than 30 minutes), the Auction timer will be extended 30 minutes.
                </div>
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  After the auction has finished, the highest bidder can RECEIVE their NFT. (Winning item will be sent to the player’s in-game inventory)
                </div>
                <div className="opacity-40 mb-[12px]">Create an auction</div>
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  Only Epic and Legendary unlocked items can be auctioned
                </div>
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  Creation fee: 20 MSTR
                </div>
                <div className=" flex mb-[12px]">
                  <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
                  You will receive 96% of the final bid
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
