import dayjs from "dayjs";
import { MongenRace } from "interfaces";
import _ from "lodash";
import { useEffect, useState } from "react";
import { api, ui } from "services";
import { BetStatus, EventAction, sendGameMessage } from "./game/config";
import Loading from "./loading";
interface HistoryItem {
  amount: number;
  created_at: string;
  customer_id: number;
  id: number;
  is_claimed: boolean;
  match_id: number;
  mongen_idx: number;
  updated_at: string;
  odd: number;
  claimable: number;
  winner: string;
  status: BetStatus;
}
const STATUS_COLORS = ["#eab308", "#16a34a", "#dc2626"];
export default function RacingHistory({
  onClose,
  onPageChange,
}: {
  onClose: Function;
  onPageChange: Function;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<HistoryItem[]>(null);
  const [total, setTotal] = useState<number>(0);
  const [pages, setPages] = useState<number[]>([]);
  async function onReplayCLick(item: HistoryItem) {
    if (item.status === BetStatus.Pending) {
      return;
    }
    let data = await api.postEventDeal("/mongen-racing/get-match-result", {
      match_id: item.match_id,
    });
    let payload = {
      game: data.frames,
      rank: data.mongen_rank,
      mongen_infos: data.mongenInfos,
    };
    onPageChange("game");
    sendGameMessage(EventAction.StartGame, payload);
  }
  const LIMIT = 4;
  useEffect(() => {
    async function loadHistory() {
      setLoading(true);
      let rs = await api.postEventDeal(`/mongen-racing/get-history-bet`, {
        limit: LIMIT,
        offset: page * LIMIT,
      });
      setData(rs.data);
      setTotal(rs.total);

      let nPage = Math.ceil(rs.total / LIMIT);
      let tmp = [];
      for (var i = 0; i < nPage; i++) {
        tmp.push(i);
      }
      setPages(tmp);
      setLoading(false);
    }
    loadHistory();
  }, [page]);
  async function claim(item: HistoryItem) {
    if (!(!item.is_claimed && item.status === BetStatus.Win)) {
      return;
    }
    let rs = await api.postEventDeal("/mongen-racing/claim-bet-transaction", {
      bet_id: item.id,
    });
    let tmp = _.cloneDeep(data);
    tmp.forEach((t: HistoryItem, index: number) => {
      if (t.id === item.id) {
        tmp[index] = rs;
      }
    });
    setData(tmp);
    api.refreshCustomerInfo();
    ui.showPopup("Claim reward", "Claim success");
  }

  return (
    <div className="relative border-2 border-[#562C1D] bg-[#CB8D57] text-[#5B3F2C] rounded-xl w-[900px] mx-auto">
      <img
        src="assets/game/ui/close.png"
        onClick={() => {
          onClose();
        }}
        className="absolute w-10 h-10 right-2 top-2 cursor-pointer z-10"
      />
      <div className="mt-4 relative">
        <p className="text-center text-xl font-bold">My History</p>
      </div>
      <div className="bg-[#FDE8B1] p-4 rounded-xl mt-4 font-cookie">
        <div className="w-full flex font-cookie">
          <p className="w-2/12">Time</p>
          <p className="w-2/12 text-center">Betted Mongen</p>
          <p className="w-1/12 text-center">Amount</p>
          <p className="w-1/12 text-center">Status</p>
          <p className="w-1/12 text-center">Winner</p>
          <p className="w-1/12 text-center">Claimable</p>
          <p className="w-3/12"></p>
        </div>
        <div className="mt-2">
          <div className="h-[180px]">
            {loading ? (
              <Loading />
            ) : (
              <div>
                {data.map((item: HistoryItem, index: number) => (
                  <div className="w-full items-center flex font-cookie pt-1 h-[40px] border-t-2 border-[#df9351]">
                    <p className="w-2/12">
                      {dayjs(item.created_at).format("YYYY-MM-DD HH:mm")}
                    </p>
                    <p className="w-2/12 text-center">{MongenRace[item.mongen_idx]}</p>
                    <p className="w-1/12 text-center">{item.amount}</p>
                    <p
                      className="w-1/12 text-center"
                      style={{ color: STATUS_COLORS[item.status] }}
                    >
                      {BetStatus[item.status]}
                    </p>
                    <p className="w-1/12 text-center">
                      {item.winner ?? "-"}
                    </p>
                    <p className="w-1/12 text-center">
                      {item.status === BetStatus.Win && item.odd
                        ? Math.floor(item.odd * item.amount)
                        : "-"}
                    </p>
                    <div className="flex gap-1 w-3/12 mx-auto">
                      <button
                        className="w-[100px] relative"
                        onClick={() => {
                          claim(item);
                        }}
                      >
                        <img
                          src="assets/game/ui/button.png"
                          className={`w-full ${!(
                              item.status === BetStatus.Win && !item.is_claimed
                            ) && "grayscale"
                            }`}
                        />
                        <p className="text-white font-bold font-cookie absolute top-0 left-0 w-full text-center mt-2">
                          Claim
                        </p>
                      </button>
                      <button
                        className="w-[100px] relative"
                        onClick={() => {
                          onReplayCLick(item);
                        }}
                      >
                        <img
                          src="assets/game/ui/button.png"
                          className={`w-full ${item.status === BetStatus.Pending && "grayscale"
                            }`}
                        />
                        <p className="text-white font-bold font-cookie absolute top-0 left-0 w-full text-center mt-2">
                          Replay
                        </p>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex gap-1">
            <p className="font-semibold pt-3">Page:</p>
            <div className="w-[826px] overflow-x-auto overflow-y-hidden p-2">
              <div className="flex gap-2">
                {pages.map((i: number) => (
                  <p
                    onClick={() => {
                      setPage(i);
                    }}
                    className={`cursor-pointer border-2 inline-block rounded-md min-w-[50px] mr-1 text-center font-bold h-8 pt-1 border-[#df9351] ${i === page && "bg-[#df9351] text-white"
                      }`}
                  >
                    {i + 1}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
