import { useEffect, useState } from "react";
import { api, ui } from "services";
import { EventAction, sendGameMessage, TrapType } from "./game/config";
import StaticAnim from "../../services/staticanim";
import _ from "lodash";
import dayjs from "dayjs";
import RacingHistory from "./history";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
enum MatchState {
  Bet,
  Play,
  End,
}
interface ConfigInterface {
  BETTED_ODD_RATE: number;
  BOOST_DURATION: number;
  GAME_DURATION: number;
  MAX_ACC: number;
  MAX_POS: number;
  MAX_SPEED: number;
  MIN_ACC: number;
  MIN_SPEED: number;
  MONGEN_LENGTH: number;
  TRAPS_DURATION: number[];
  WHEEL_BACK_JACKPOT: number;
  WHEEL_OPTION_RATE: number[];
  WHEEL_REVENUE: number;
  now: Date;
}
interface MongenInfo {
  topSpeed: number;
  acceleration: number;
  boostRate: number;
  evasionRate: number;
  dna: number[][];
  speed: number;
  pos: number;
  boost_time: number;
  trap_infos: { trap_type: TrapType; duration: number }[];
  dir: number;
}
class EventInfo {
  id: number;
  start: Date;
  time_end_bet: Date;
  end: Date;
  pool_id: number;
  state: MatchState;
  mongen_infos: MongenInfo[];
  is_active: boolean;
  frames: any;
  mongen_rank: number[];
  totalBets: number[];
  min_bet?: number;
}
class EventInterface {
  eventInfo: EventInfo;
  customerBetInfos: number[];
  matchBetInfos: number[];
  odds: number[];
}
export default function Home({
  page,
  onPageChange,
}: {
  page: string;
  onPageChange: Function;
}) {
  const userInfo = useSelector((state: RootState) => state.user);
  const [event, setEvent] = useState<EventInterface>(null);
  const [config, setConfig] = useState<ConfigInterface>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [time, setTime] = useState<number>(0);
  const [history, setHistory] = useState<boolean>(false);
  const [showRule, setShowRule] = useState<boolean>(false);
  async function loadEventInfo() {
    try {
      setLoading(true);
      let url = userInfo?.id
        ? "/mongen-racing/get-current-match"
        : "/mongen-racing/get-public-current-match";
      let [event, config] = await Promise.all([
        api.postEventDeal(url, {}),
        api.postEventDeal("/get-config", {}),
      ]);
      setConfig(config);
      if (event) {
        setEvent(event);
      }
      let time = dayjs(event.eventInfo.time_end_bet)
        .add(30, "second")
        .diff(dayjs(), "second");
      setTime(time);
      let interval = setInterval(() => {
        let time = dayjs(event.eventInfo.time_end_bet)
          .add(30, "second")
          .diff(dayjs(), "second");
        if (time < 0) {
          clearInterval(interval);
        }
        setTime(time);
      }, 1000);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function runGame() {
      let data = await api.postEventDeal("/mongen-racing/get-match-result", {
        match_id: event.eventInfo.id,
      });
      let payload = {
        game: data.frames,
        rank: data.mongen_rank,
        mongen_infos: event.eventInfo.mongen_infos,
      };
      onPageChange("game");
      sendGameMessage(EventAction.StartGame, payload);
    }
    if (time < 0) {
      runGame();
    }
  }, [time]);
  function displayTime() {
    if (time < 0) {
      return "";
    }
    let hour = Math.floor(time / 3600);
    let min = Math.floor((time % 3600) / 60);
    let second = time % 60;
    return `${(hour + "").padStart(2, "0")}:${(min + "").padStart(2, "0")}:${(
      second + ""
    ).padStart(2, "0")}`;
  }

  useEffect(() => {
    if (page === "home") {
      loadEventInfo();
    }

    let interval = setInterval(() => {
      if (page === "home") {
        loadEventInfo();
      }
    }, 15 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [page]);

  async function placeBet(mongen_idx: number, amount: number) {
    if (!userInfo?.id) {
      return ui.showPopup("", "Please sign in to join game");
    }
    try {
      let rs = await api.postEventDeal("/mongen-racing/bet", {
        mongen_idx,
        amount,
      });
      let tmp = _.cloneDeep(event);
      tmp.customerBetInfos = rs.customerBetInfos;
      tmp.matchBetInfos = rs.matchBetInfos;
      tmp.odds = rs.odds;
      setEvent(tmp);
      api.refreshCustomerInfo();
    } catch (error: any) {
      ui.showPopup("", error.message);
    }
  }
  function renderRule() {
    return (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 z-[20]">
        <div className="relative border-2 border-[#562C1D] bg-[#CB8D57] text-[#5B3F2C] rounded-xl w-[900px] mx-auto mt-20">
          <img
            src="assets/game/ui/close.png"
            onClick={() => {
              setShowRule(false);
            }}
            className="absolute w-10 h-10 right-2 top-2 cursor-pointer z-10"
          />
          <div className="mt-4 relative">
            <p className="text-center text-xl font-bold">Rule</p>
          </div>
          <div className="bg-[#FDE8B1] p-4 rounded-xl mt-4 font-cookie">
            <pre>
              <p className="text-xl">
                <b>- Betting phase</b>
              </p>
              <p>
                The system will spawn 5 mongen with different stats. The player
                selects the winning Mongen and places a bet.
              </p>

              <p>
                Bet with staked MAG in the game account balance. (Minimum 1
                MAG/time).
              </p>

              <p>
                Winnings will be equal to the bet amount multiplied by the final
                odds.
              </p>

              <p>
                The odds will change based on the players' total amount bet on
                that mongen.
              </p>

              <p>
                If mongen finishes first in the race, all players who have bet
                on that mongen will be rewarded.
              </p>

              <p>
                0.2% of the Bet amount will be counted towards MAG revenue, we
                will use it for MAG Vault and Staking in the future
              </p>

              <p className="mt-4 text-xl">
                <b>- Racing phase:</b>
              </p>

              <p>
                The race will start every hour. When the betting time expires,
                players cannot place any more bets and the match will start.
              </p>

              <p>
                Each match will include 5 runs for 5 mongen. On each run track,
                there will be 20 traps placed in random positions. Trap will be
                random 1 of the following 3 types of traps
              </p>

              <p>
                Ice trap: Reduce 50% of Current speed immediately & reduce 50%
                acceleration of Mongen for 6 seconds after that
              </p>

              <p>
                Electric trap: Stun Mongen for 3 seconds. Speed will be set at 0
                after that
              </p>

              <p>
                Confused trap: Change the direction of the mongen to the
                opposite direction for 2 seconds with a 50% speed increase from
                the current speed
              </p>

              <p className="mt-4 text-xl">
                <b>- Mongen's stats:</b>
              </p>

              <p>Top Speed: The fastest speed achievable</p>

              <p>Acceleration</p>

              <p>Evasion Rate: Rate of immunity to Trap's effects</p>

              <p>
                Boost Rate: Rate to increase doubled current speed for 3
                seconds.
              </p>
            </pre>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {showRule && renderRule()}
      {event === null ? (
        loading ? (
          <p>loading...</p>
        ) : (
          <p className="text-[#870709] text-2xl mt-16 text-center w-full font-cookie">
            There's no event. Please come back later.
          </p>
        )
      ) : (
        <div>
          {/* countdown */}
          <div className="w-full flex justify-center">
            <div className="relative  w-[300px]">
              <img src="assets/game/ui/countdown.png" className="w-full" />
              <p className="absolute bottom-3 text-3xl font-cookie font-bold text-[#822804] w-full text-center pl-[80px]">
                {displayTime()}
              </p>
            </div>
          </div>
          <div className="flex gap-1 mt-[54px]">
            {event.eventInfo.mongen_infos.map(
              (m: MongenInfo, index: number) => (
                <div key={index} className="w-1/5">
                  <Mongen
                    info={m}
                    onSetBet={(amount: number) => {
                      placeBet(index, amount);
                    }}
                    config={config}
                    totalBet={event.matchBetInfos[index]}
                    odd={event.odds[index]}
                    yourBet={event.customerBetInfos[index]}
                    min_bet={event.eventInfo.min_bet}
                  />
                </div>
              )
            )}
          </div>
        </div>
      )}
      <div className="mt-4 flex justify-center">
        <button
          className="relative"
          onClick={() => {
            setShowRule(true);
          }}
        >
          <img src="assets/game/ui/btn_red.png" />
          <p className="text-white font-cookie text-md absolute top-0 left-0 w-full h-full mt-4">
            Rule
          </p>
        </button>
        <button
          className="relative"
          onClick={() => {
            setHistory(true);
          }}
        >
          <img src="assets/game/ui/button.png" />
          <p className="text-white font-cookie text-md absolute top-0 left-0 w-full h-full mt-4">
            My History
          </p>
        </button>
      </div>
      {history && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80">
          <div className="absolute bottom-10 w-full left-0">
            <RacingHistory
              onPageChange={onPageChange}
              onClose={() => {
                setHistory(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
function Mongen({
  info,
  config,
  yourBet,
  totalBet,
  odd,
  onSetBet,
  min_bet,
}: {
  yourBet: number;
  totalBet: number;
  odd: number;
  info: MongenInfo;
  config: ConfigInterface;
  onSetBet: Function;
  min_bet: number;
}) {
  const [bet, setBet] = useState<number>(0);
  return (
    <div className="border-2 border-[#562C1D] bg-[#CB8D57] text-[#5B3F2C] rounded-xl w-full mx-auto relative font-cookie">
      <div className="w-[180px] h-[180px] absolute -top-20 -left-2">
        <StaticAnim dna={info.dna} lock={[]} />
      </div>
      <div className="bg-[#FDE8B1] px-2 py-1 rounded-xl mt-[70px] pt-[30px]">
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-center">
            <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
              Acceleration
            </p>
            <div className="w-1/2">
              <Progress value={info.acceleration / config.MAX_ACC} />
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
              Top Speed
            </p>
            <div className="w-1/2">
              <Progress value={info.topSpeed / config.MAX_SPEED} />
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
              Evasion
            </p>
            <div className="w-1/2">
              <Progress value={info.evasionRate} />
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
              Boost
            </p>
            <div className="w-1/2">
              <Progress value={info.boostRate} />
            </div>
          </div>
        </div>
        <div className="border-2 border-[#562C1D] bg-[#F2CB91] p-1 mt-4 rounded-md font-cookie flex">
          <input
            value={bet ? bet + "" : ""}
            onChange={(evt: any) => {
              setBet(Number(evt.target.value));
            }}
            type="text"
            className="w-full bg-[#F2CB91] placeholder-[#cb8d57] px-2"
            placeholder="Enter amount"
          />
          <img src="/img/reward/mag.png" alt="mag" className="w-6 h-6" />
        </div>

        <div className="mb-4">
          <div className="h-[8px] text-[11px] text-red font-bold">
            {min_bet && min_bet > bet && <>Minimum amount: {min_bet} </>}
          </div>
        </div>

        <div className="flex gap-1 items-center mt-1">
          <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
            Your Bet
          </p>
          <div className="w-1/2 text-[11px] font-bold font-cookie text-right">
            {yourBet}
          </div>
        </div>
        <div className="flex gap-1 items-center mt-1">
          <p className="font-cookie text-[11px] w-1/2 text-[#822804]">
            Total Bet
          </p>
          <div className="w-1/2 text-[11px] font-bold font-cookie text-right">
            {totalBet}
          </div>
        </div>
        <div className="flex gap-1 items-center mt-1">
          <p className="font-cookie text-[11px] w-1/2 text-[#822804]">Odds</p>
          <div className="w-1/2 text-[11px] font-bold font-cookie text-right">
            {Math.floor(odd * 100) / 100}
          </div>
        </div>
        <button
          className="w-[100px] mx-auto block mt-4 relative"
          onClick={() => {
            if (!bet) {
              return;
            }
            if (min_bet > 0 && min_bet > bet) {
              return;
            }
            setBet(0);
            onSetBet(bet);
          }}
        >
          <img
            src="assets/game/ui/button.png"
            className={`w-full ${!bet && "grayscale"} ${
              min_bet > 0 && min_bet > bet && "grayscale"
            }`}
          />
          <p className="absolute top-0 left-0 w-full text-center font-cookie text-white mt-1">
            Submit
          </p>
        </button>
      </div>
    </div>
  );
}
export function Progress({ value }: { value: number }) {
  return (
    <div className="border border-[#562C1D] w-full h-2 rounded-xl">
      <div
        className="bg-[#D92B2B] h-full rounded-xl"
        style={{ width: `${value * 100}%` }}
      ></div>
    </div>
  );
}
