import axios from "axios";
import Config, { SSO_META, DEFAULT_ITEMS_PER_PAGE } from "config";
import { t } from "i18next";
import { LoginType, TicketRarity, WALLET_PROVIDER } from "interfaces";
import { setLoginType } from "reducer/logintype.slice";
import { clearSSOMeta, setSSOMeta } from "reducer/ssometa.slice";
import { store } from "reducer/store";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import ui from "./ui";
import utils from "./utils";
import keplrService from "./keplr";

//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const ethereum = window.ethereum;
const web3 = new Web3(Web3.givenProvider);

// config api method
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      vv: "Web 4 999",
    },
    body: JSON.stringify(data),
  });
  let refresh_token = rs.headers.get("refresh-token");
  if (refresh_token) {
    localStorage.setItem("token", refresh_token);
    console.log({ refresh_token });
  }
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postEventDeal(url: string, data: any): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.EventApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  let refresh_token = rs.headers.get("refresh-token");
  if (refresh_token) {
    localStorage.setItem("token", refresh_token);
    console.log({ refresh_token });
  }
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function get(url: string): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  let refresh_token = rs.headers.get("refresh-token");
  if (refresh_token) {
    localStorage.setItem("token", refresh_token);
    console.log({ refresh_token });
  }
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${utils.formatHostUrl(Config.ApiHost)}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}

function getChainId(type: LoginType) {
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      return SSO_META().network[LoginType[type]].chainId;
    case LoginType.Terra:
      return "pisco-1";
  }
}

async function changeNetwork(type: LoginType) {
  let chainData = SSO_META().network[LoginType[type]];
  try {
    console.log(chainData?.chainId);
    // @ts-ignore
    await window.ethereum?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainData?.chainId }],
    });
  } catch (switchError: any) {
    console.log({ switchError });
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      console.log("Add net work");
      // @ts-ignore
      await window.ethereum
        ?.request({
          method: "wallet_addEthereumChain",
          params: [chainData],
        })
        .catch((error: any) => {
          console.log(error);
          throw error;
        });
    }
  }
}

async function checkNetwork(type: LoginType, src?: string) {
  console.log({ src, loginType: LoginType[type] });
  if (Config.AntiWalletAuth.includes(type)) return;

  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      // @ts-ignore
      const currentNetwork = await window.ethereum.request({
        method: "eth_chainId",
      });
      if (getChainId(type) !== currentNetwork) {
        await changeNetwork(type);
        // @ts-ignore
        const changedNetwork = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (getChainId(type) !== changedNetwork) {
          throw new Error("failed to switch network");
        }
        store.dispatch(setLoginType(type));
      }
      break;
    case LoginType.Aura:
      await keplrService.keplrSuggestChain(type);
      await delay(500);
      store.dispatch(setLoginType(type));
      break;
  }
}
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

async function login(
  type: LoginType,
  social_access_token?: string,
  email?: string,
  password?: string
) {
  localStorage.clear();
  let nonce = await getNonce();
  await checkNetwork(type);
  let sign, account;
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      account = await ethereum?.request({ method: "eth_requestAccounts" });
      sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
      break;
  }
  let { customerInfo, token } = await api.post(
    "/customer/login-support-customer",
    {
      sign,
      nonce,
      type,
      social_access_token,
      email,
      password,
    }
  );
  let userInfo = {
    id: customerInfo.A,
    name: customerInfo.D,
    ...customerInfo,
  };
  await api.setStateLogin(token, userInfo);
}

async function refreshCustomerInfo() {
  let customerInfo = await api.post("/event/get-customer-info", {});
  let userInfo = {
    id: customerInfo.A,
    name: customerInfo.D,
    ...customerInfo,
  };
  let token = localStorage.getItem("token");
  await api.setStateLogin(token, userInfo);
}

async function setStateLogin(token: string, customerInfo: any) {
  localStorage.setItem("token", token);
  store.dispatch(bulkSetUserInfo(customerInfo));
}

async function getNonce() {
  let rs = await api.post("/nonce/create-nonce", {});
  return rs.nonce;
}

async function uploadImage(image: any): Promise<any> {
  return postFormData(
    `${utils.formatHostUrl(Config.ApiHost)}/file/upload-image`,
    { image }
  );
}

async function getSSOMeta() {
  const ssoMeta = await get("/customer/get-SSO-meta");
  store.dispatch(clearSSOMeta());
  store.dispatch(setSSOMeta(ssoMeta));
}

async function getMongenData(id: number) {
  const data = await get(`/nft/get-mongen?id=${id}`);
  return data;
}

async function getPlotData(id: number) {
  const data = await get(`/nft/get-plot?id=${id}`);
  return data;
}

async function getReferralEvent(customer_id: number) {
  const data = await post("/event/get-referral-event", {
    customer_id,
  });
  return data;
}

async function getLeaderboard() {
  const data = await post("/event/get-all-leaderboard-referral-event", {});
  return data;
}

async function getAuction(
  url: string,
  id?: any,
  user_id?: number,
  page?: number,
  limit: number = DEFAULT_ITEMS_PER_PAGE
): Promise<any> {
  let newUrl = id
    ? `${utils.formatHostUrl(Config.AuctionApiHost)}${url}?id=${id}`
    : `${utils.formatHostUrl(Config.AuctionApiHost)}${url}`;
  newUrl = user_id
    ? `${utils.formatHostUrl(Config.AuctionApiHost)}${url}?user_id=${user_id}`
    : newUrl;
  newUrl = page ? `${newUrl}&page=${page}&limit=${limit}` : newUrl;
  let rs = await fetch(newUrl, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}

async function postAuction(url: string, data?: any): Promise<any> {
  let token = localStorage.getItem("token");
  let rs = await fetch(`${utils.formatHostUrl(Config.AuctionApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 201:
      return rs;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      ui.alertFailed(t(err?.message ?? err), "");
      throw new Error(err);
  }
}

async function getBidData(
  url: string,
  user_id: number,
  page?: number,
  limit?: number
) {
  const data = await getAuction(url, "", user_id, page, limit);
  return data;
}

async function getBidItem(id: any) {
  const data = await getAuction("/auction-items/item", id);
  return data;
}

async function getBidItemP2P(id: any) {
  const data = await getAuction("/p2p-auction/item", id);
  return data;
}

async function createAuctionP2P(data: any) {
  const res = await postAuction("/p2p-auction/new-auction-item", data);
  return res;
}

async function cancelAuctionP2P(data: any) {
  const res = await postAuction("/p2p-auction/cancel-auction-item", data);
  return res;
}

async function createBid(
  url: string,
  id: string,
  price: number,
  user_id: number,
  current_price: number
) {
  let data = { id, price, user_id, current_price };
  const res = await postAuction(url, data);
  return res;
}

async function getTopBid(url: string, id?: string) {
  const data = await getAuction(url, id);
  return data;
}

async function claimNft(id: string, user_id: number) {
  let data = { id, user_id };
  const res = await postAuction("/auction-items/claim", data);
  return res;
}

async function getTicketPool() {
  return post("/dao/get-ticket-pool", {});
}

async function getCurrentEvent() {
  return post("/dao/get-current-event", {});
}

async function submitTicket(quantity: number, rarity: TicketRarity) {
  return post("/dao/submit-ticket", {
    quantity,
    rarity,
  });
}

async function getPublicConfig() {
  return get("/config/get-public-site");
}
async function loginCosmos(type: LoginType) {
  let nonce = await getNonce();
  let accountAddress = await keplrService.connect(WALLET_PROVIDER.KEPLR);
  await checkNetwork(type);
  let signature = await keplrService.signMessage(accountAddress, nonce);

  let { customerInfo, token, authInfos, is_2fa_enabled } = await api.post(
    "/customer/login-support-customer",
    {
      sign: JSON.stringify(signature),
      nonce,
      type,
    }
  );
  await api.setStateLogin(token, customerInfo);
}
// export
const api = {
  loginCosmos,
  getPublicConfig,
  getTicketPool,
  getCurrentEvent,
  submitTicket,
  refreshCustomerInfo,
  postEventDeal,
  getReferralEvent,
  setStateLogin,
  getNonce,
  post,
  get,
  postFormData,
  uploadImage,
  login,
  getMongenData,
  getSSOMeta,
  getLeaderboard,
  getBidData,
  getBidItem,
  createBid,
  getTopBid,
  claimNft,
  getBidItemP2P,
  createAuctionP2P,
  cancelAuctionP2P,
  getPlotData,
};
export default api;
